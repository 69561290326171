export interface IHttpQueryClient {
    get<T>(relativeUrl: string, body?: any): Promise<Response<T>>;
    getUrlWithToken(relativeUrl: string): string;
}

export interface IHttpCommandClient {
    post(relativeUrl: string, body?: any): Promise<Result>;
    postWithResponse<T>(relativeUrl: string, body?: any): Promise<Response<T>>;
    postFileWithResponse<T>(relativeUrl: string, file: File,
        onUploadProgress: (progresEvent: ProgressEvent) => void): Promise<Response<T>>;
}

export interface IDeprecatedHttpClient {
    get<T>(relativeUrl: string, body?: any): Promise<Response<T>>;
    post(relativeUrl: string, body?: any): Promise<Result>;
    postWithResponse<T>(relativeUrl: string, body?: any): Promise<Response<T>>;
    postFileWithResponse<T>(relativeUrl: string, file: File,
        onUploadProgress: (progresEvent: ProgressEvent) => void): Promise<Response<T>>;
    getUrlWithToken(relativeUrl: string): string;
};

export interface IResult {
    success: boolean;
    messages?: string[];
}

export class Result implements IResult {
    constructor(
        public success: boolean,
        public messages?: string[]
    ) {
    }

    AsResponse<T>() {
        return this as unknown as Response<T>;
    }

    GetPayload<T>() {
        return this.AsResponse<T>().payload;
    }
}

export class Response<T> extends Result {
    constructor(
        success: boolean,
        public payload: T,
        messages?: string[],
    ) {
        super(success, messages);
    }
}