import autenticacionesAPI from 'client/pages/Usuarios/api/autenticacionesAPI';
import clientStore from 'client/store/clientStore';
import themeService from 'client/services/theming/themeService';

const loginService = {

    login: (email: string, password: string, recordarme: boolean) =>
        autenticacionesAPI
            .login(email, password, recordarme)
            .then(r => {
                if (r.success) {
                    clientStore.usuario.save(r.payload);
                    themeService.applyTheme(r.payload.config.temaOscuroActivado);
                };
                return r;
            }),


    logout: () =>
        autenticacionesAPI
            .logout()
            .finally(() => {
                clientStore.empresaEnContexto.remove();
                // We defer these actions in orther to avoid 
                // unauthoriezed request to GetMiFoto... etc.
                // We do the cleanup after the redirection
                window.setTimeout(() => {
                    clientStore.usuario.remove();
                    clientStore.versionDeFotoDePerfil.remove();
                }, 0);
            })
};

export default loginService;