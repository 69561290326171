// Styles
import './App.css';
import 'client/infrastructure/styles/transitions/transitions.css';
import 'client/infrastructure/styles/reports/report.css';
// Components
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy, useState, useEffect, FC } from 'react';
import LoginDialog from 'client/components/usuarios/LoginDialog';
import { getUnauthorizedErrorStream } from 'client/infrastructure/http/httpClient';
import { IUsuarioAutenticado } from 'client/store/model/usuario';
import { useRerenderingOnPhoneScreenSizeChanged } from './layouts/admin/useScreenSize';
import Loading from './Loading';
import httpHeadersProvider from './infrastructure/http/httpDefaultHeadersProvider';
import { DefaultHeaderKeys } from './infrastructure/http/httpDefaultHeadersProvider.types';
import usePromises from './infrastructure/promises/usePromises';
import portalQueryAPI from 'Portal/Model/Handlers/portalQueryAPI';
import idDisenoDePortal from 'Portal/Model/idDisenoDePortal';
import { useMemo } from 'react';
import { isUpdateAvailable } from 'serviceWorkerRegistration';
import PWAUpdateDialog from './PWAUpdateDialog';

// Home
const W3Schools = lazy(() => import('Portal/Views/W3Schools/Home'));
const RealState = lazy(() => import('Portal/Views/RealState/Index'));

// Other
const IntroducirNuevoPassword = lazy(() => import('client/pages/NuevoPassword/IntroducirNuevoPassword'));
const AceptarInvitacionParaSerUsuario = lazy(() => import('client/pages/AceptarInvitacion/AceptarInvitacionParaSerUsuario'));
const Configuracion = lazy(() => import('client/pages/Configuracion/Configuracion'));
const DevOps = lazy(() => import('client/pages/DevOps/DevOps'));
// Erp
const Erp = lazy(() => import('client/pages/Erp/Erp'))
// Not found
const NotFound = lazy(() => import('client/pages/NotFound/NotFound'));

const App: FC = () => {

    // Hooks

    // This fix a lot of issues in UI React when it does not detect the screen size change
    // This is related to the animation of the sidenav when mobile it hides gracefully, but
    // reacts does not see it.
    // Example: See large breadcrumb from desktop screen to phone screen.
    // Remarks: This does not apply yet to Home route, but we start here anyway.
    useRerenderingOnPhoneScreenSizeChanged();
    const { newPromise } = usePromises();
    
    // States
    const [hideDialog, setHideDialog] = useState(true);
    const [authError, setAuthError] = useState<any>(undefined);
    const [resetApp, setResetApp] = useState(false);
    const [portalDesign, setPortalDesign] = useState<string | undefined>();
    const [hideUpdateDialog, setHideUpdateDialog] = useState(true);

    // FX 
    useEffect(() => {
        const sub = getUnauthorizedErrorStream().subscribe(error => {
            if (error) {
                setAuthError(error);
                setHideDialog(false);
            }
        });
        return () => {
            sub.unsubscribe();
        }
    }, []);

    useEffect(() => {
        // Display mode detection
        // Info1: https://developer.mozilla.org/es/docs/Web/CSS/@media/display-mode
        // Info:2 https://stackoverflow.com/questions/41742390/javascript-to-check-if-pwa-or-mobile-web

        const displayModes = ['standalone', 'minimal-ui', 'fullscreen', 'browser'];
        for (const modeName of displayModes) {
            if (window.matchMedia(`(display-mode: ${modeName})`).matches) {
                httpHeadersProvider.addOrUpdate({
                    key: DefaultHeaderKeys.DisplayMode,
                    value: modeName
                });
                //console.log('Display mode detected ' + modeName);
                break;
            }
            else {
                //console.log('No display mode recorded');
            }
        };
    }, []);

    useEffect(() => {
        newPromise(portalQueryAPI.getDisenoDelPortalEstablecido())
            .then(r => {
                if (!r.success) window.alert(
                    'Error al obtener dise\u00f1o del portal. Recargue la p\u00e1gina por favor o verifique su conexi\u00f3n a internet.');
                else setPortalDesign(r.payload);
            });

    }, [newPromise]);

    useEffect(() => {
        const sub = isUpdateAvailable
            .subscribe(newUpdateFound => {
                if (newUpdateFound)
                    setHideUpdateDialog(false);
            });
        return () => sub.unsubscribe();
    }, []);

    // Callbacks
    const onClose = (result?: IUsuarioAutenticado) => {
        setHideDialog(true);

        if (result && authError.config && authError.config.method === 'get')
            setResetApp(true);
    };

    const doReset = () => {
        window.setTimeout(() => setResetApp(false));

        return <Loading />;
    };

    // Memo
    const homeComponent = useMemo(() => {
        switch (portalDesign) {
            case idDisenoDePortal.w3Schools:
                return W3Schools;
            case idDisenoDePortal.realState:
                return RealState;
            default:
                return undefined;
        }

    }, [portalDesign]);

    return resetApp
        ? doReset() :
        !homeComponent
            ? <Loading />
            : <>
                <Router>
                    <Suspense fallback={<Loading />}>
                        {// React router parameters: https://reacttraining.com/react-router/web/example/url-params
                        }
                        <Switch>
                            {/*-------- HOME ------- */}
                            <Route exact path="/" component={homeComponent} />
                            <Route exact path="/home" component={homeComponent} />
                            <Route path="/home/:empresa" component={homeComponent} />

                            {/*-------- OTHER ------- */}
                            <Route path="/aceptar-invitacion-para-ser-usuario" component={AceptarInvitacionParaSerUsuario} />
                            <Route path="/introducir-nuevo-password" component={IntroducirNuevoPassword} />
                            <Route path="/configuracion" component={Configuracion} />
                            <Route path="/devops" component={DevOps} />

                            {/*-------- ERP ---------- */}
                            <Route path="/erp/:empresa" component={Erp} />

                            {/*-------- NOT FOUND ------- */}
                            <Route path="*" component={NotFound} />
                        </Switch>
                    </Suspense>
                </Router>
                <LoginDialog authError={authError} hideDialog={hideDialog} onClose={onClose} />
                <PWAUpdateDialog
                    hidden={hideUpdateDialog}
                    onDismiss={() => setHideUpdateDialog(true)}
                />
            </>
};

export default App;