import { IHttpHeader, DefaultHeaderKeys } from "./httpDefaultHeadersProvider.types";

const currentHeaders: IHttpHeader[] = [];

const httpHeadersProvider = {
    // TODO: Move here user position and token logic here
    addOrUpdate: (header: IHttpHeader) => {
        // Empresa is an example of added header

        if (header.key === DefaultHeaderKeys.Autorization
            || header.key === DefaultHeaderKeys.UserPosition)
            throw new Error('Can not add reserved header key');

        const found = currentHeaders.find(x => x.key === header.key);
        if (found) {
            if (found.value !== header.value)
                found.value = header.value;
        }
        else
            currentHeaders.push(header);
    },

    removeHeader: (key: string) => {
        const found = currentHeaders.findIndex(x => x.key === key);
        if (found > -1) // Why this if? https://stackabuse.com/remove-element-from-an-array-in-javascript/#:~:text=To%20remove%20a%20particular%20element,is%20not%20in%20the%20array.
            currentHeaders.splice(found, 1);
    },

    getHeaders: (): IHttpHeader[] => {
        return currentHeaders;
    }

};

export default httpHeadersProvider;