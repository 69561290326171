export interface ITokenResolver {
    getToken(): string | undefined
};

let currentTokenResolver: ITokenResolver | undefined = undefined;

const tokenResolverProvider = {
    getTokenResolver: () => {
        return currentTokenResolver;
    } ,
    setTokenResolver: (resolver: ITokenResolver) => {
        currentTokenResolver = resolver;
    }
};

export default tokenResolverProvider;
