import { BehaviorSubject } from "rxjs";
import { useState, useEffect } from "react";
import { delay, filter } from "rxjs/operators";

// Source: https://developer.microsoft.com/en-us/fluentui#/styles/web/layout
const phoneMedia = window.matchMedia(`(max-width: 639px)`); // medium
const tabletMedia = window.matchMedia(`(max-width: 1023px)`); // large
const notebookMedia = window.matchMedia(`(max-width: 1365px)`); // extra large

// TODO: this could be better initialized, not just defaulting to false
const isPhoneSubject = new BehaviorSubject<{ isPhone: boolean, version: number }>({ isPhone: false, version: 0 });
const isTabletSubject = new BehaviorSubject<{ isTablet: boolean, version: number }>({ isTablet: false, version: 0 });
const isNotebookSubject = new BehaviorSubject<{ isNotebook: boolean, version: number }>({ isNotebook: false, version: 0 });

var isPhoneListenerRunning = false;
var isTabletListenerRunning = false;
var isNotebookListenerRunning = false;

const useScreenSize = () => {

    const [isPhoneScreenSize, setIsPhoneScreenSize] = useState(isPhoneSubject.getValue().isPhone);
    const [isTabletScreenSize, setIsTabletScreenSize] = useState(isTabletSubject.getValue().isTablet);
    const [isNotebookScreenSize, setIsNotebookScreenSize] = useState(isNotebookSubject.getValue().isNotebook);

    useEffect(() => {
        startListenersIfNeeded();

        const phoneSub = isPhoneSubject.subscribe(e => setIsPhoneScreenSize(e.isPhone));
        const tabletSub = isTabletSubject.subscribe(e => setIsTabletScreenSize(e.isTablet));
        const isNotebookSub = isNotebookSubject.subscribe(e => setIsNotebookScreenSize(e.isNotebook));

        return () => {
            phoneSub.unsubscribe();
            tabletSub.unsubscribe();
            isNotebookSub.unsubscribe();

            closeListenerIfNeeded();
        }
    }, []);

    return {
        isPhoneScreenSize,
        isTabletScreenSize,
        isNotebookScreenSize,
        isDesktopScreenSize: !isNotebookScreenSize
    }
};

export default useScreenSize;

export const useRerenderingOnPhoneScreenSizeChanged = (delayMs: number = 550) => {

    const [version, setVersion] = useState(isPhoneSubject.getValue().version);

    useEffect(() => {
        startListenersIfNeeded();

        const sub = isPhoneSubject
            .pipe(delay(delayMs), filter(x => x.version === isPhoneSubject.getValue().version))
            .subscribe(isMobileEvent => setVersion(isMobileEvent.version));

        return () => {
            sub.unsubscribe();

            closeListenerIfNeeded();
        }
    }, [delayMs]);

    return { version }
};

function startListenersIfNeeded() {
    if (!isPhoneListenerRunning) {
        //see more: https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_matchmedia
        // Call listener function at run time
        phoneMediaQueryHandler(phoneMedia);

        phoneMedia.addListener(phoneMediaQueryHandler);

        console.debug('🆗 Starting phone screen size listener');
        isPhoneListenerRunning = true;
    }

    if (!isTabletListenerRunning) {
        tabletMediaQueryHandler(tabletMedia);
        tabletMedia.addListener(tabletMediaQueryHandler);

        console.debug('🆗 Starting tablet screen size listener');
        isTabletListenerRunning = true;
    }

    if (!isNotebookListenerRunning) {
        notebookMediaQueryHandler(notebookMedia);
        notebookMedia.addListener(notebookMediaQueryHandler);

        console.debug('🆗 Starting notebook screen size listener');
        isNotebookListenerRunning = true;
    }
}

function closeListenerIfNeeded() {

    if (isPhoneSubject.observers.length < 1) {
        phoneMedia.removeListener(phoneMediaQueryHandler);

        console.debug('🛑 Stopping phone screen size listener');
        isPhoneListenerRunning = false;
    }

    if (isTabletSubject.observers.length < 1) {
        tabletMedia.removeListener(tabletMediaQueryHandler);

        console.debug('🛑 Stopping tablet screen size listener');
        isTabletListenerRunning = false;
    }

    if (isNotebookSubject.observers.length < 1) {
        notebookMedia.removeListener(notebookMediaQueryHandler);

        console.debug('🛑 Stopping notebook screen size listener');
        isNotebookListenerRunning = false;
    }
}

const phoneMediaQueryHandler = (media: MediaQueryList | MediaQueryListEvent) => {
    console.debug(media.matches ? 'Pantalla telefono activada' : 'Pantalla telefono desactivada');
    isPhoneSubject.next({ isPhone: media.matches, version: isPhoneSubject.getValue().version + 1 });
}

const tabletMediaQueryHandler = (media: MediaQueryList | MediaQueryListEvent) => {
    console.debug(media.matches ? 'Pantalla tablet activada' : 'Pantalla tablet desactivada');
    isTabletSubject.next({ isTablet: media.matches, version: isTabletSubject.getValue().version + 1 });
}

const notebookMediaQueryHandler = (media: MediaQueryList | MediaQueryListEvent) => {
    console.debug(media.matches ? 'Pantalla notebook activada' : 'Pantalla notebook desactivada');
    isNotebookSubject.next({ isNotebook: media.matches, version: isNotebookSubject.getValue().version + 1 });
}