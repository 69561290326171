import { interval } from 'rxjs';
import { IUserPosition, IUserGeolocationProvider, UserPositionErrorCodes } from './userGeolocationProvider.types';

const errorCodes = new UserPositionErrorCodes();

const refreshIntervalInMs = 60000

let currentPosition: IUserPosition = {
    success: false,
    errorCode: errorCodes.notYetResolved
};

tryUpdateUserPosition();
interval(refreshIntervalInMs).subscribe(() => tryUpdateUserPosition());

const userGeolocationProvider: IUserGeolocationProvider = {
    getPosition: () => currentPosition
};

export default userGeolocationProvider;

function tryUpdateUserPosition() {
    if (!navigator.geolocation) {
        //console.debug('Navigator does not support geolocation');

        currentPosition = {
            success: false,
            errorCode: errorCodes.notSupported
        };
        return;
    }

    navigator.geolocation.getCurrentPosition(
        p => {
            //console.debug('User location resolved', p);

            let c = p.coords;
            currentPosition = {
                success: true,
                latitude: c.latitude,
                longitude: c.longitude,
                accuracy: c.accuracy,
                altitude: c.altitude,
                altitudeAccuracy: c.altitudeAccuracy,
                heading: c.heading,
                speed: c.speed,
                timestamp: new Date(p.timestamp)
            };
        },
        error => {
            //console.debug('Error on gettting user location', error);

            let errorCode = errorCodes.unknownError;
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    errorCode = errorCodes.permissionDenied;
                    break;
                case error.POSITION_UNAVAILABLE:
                    errorCode = errorCodes.positionUnavailable;
                    break;
                case error.TIMEOUT:
                    errorCode = errorCodes.timeout;
                    break;
            }
            currentPosition = {
                success: false,
                errorCode: errorCode
            };
        },
        {
            enableHighAccuracy: true,
            timeout: refreshIntervalInMs - 1
        });
}
