import React from 'react';
import ReactDOM from 'react-dom';
import 'client/index.css';
import App from 'client/App';
import { initializeIcons } from '@fluentui/react';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import tokenResolverProvider from 'client/infrastructure/http/tokenResolver';
import { TokenProvider } from 'client/services/usuarios/tokenProvider';
import clientStore from 'client/store/clientStore';
import themeService from 'client/services/theming/themeService';
import { BehaviorSubject } from 'rxjs';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

export interface IA2HS {
    showButton: boolean;
    onUserOpenA2HSDialog?: (onAccepted?: () => void) => void;
}

let a2hsSubject = new BehaviorSubject<IA2HS>(resolveA2HS());
export let a2hsObservable = a2hsSubject.asObservable();
bootstrap();

// DONT ENABLE THIS!!!! The height 100vh breaks in full screen!
// Inject some global styles
// From: https://github.com/microsoft/create-react-app-uifabric/blob/master/src/index.tsx
//mergeStyles({
//    selectors: {
//        ':global(body), :global(html), :global(#root)': {
//            margin: 0,
//            padding: 0,
//            height: '100vh'
//        }
//    }
//});

ReactDOM.render(<App />, document.getElementById('root'));

// Importante!
// Aqu� muestra que tenemos que no olvidarnos de registrar el PWA con el SW
// https://github.com/GoogleChrome/workbox/issues/2861#issuecomment-940380941

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//#region Helpers
function bootstrap() {

    tokenResolverProvider.setTokenResolver(new TokenProvider());

    // More on icons: https://github-wiki-see.page/m/microsoft/fluentui/wiki/Using-icons
    // Every time icons are updated, replace old files with new ones if served from local
    // leave the initializeIcons('string') string parameter to serve from cdn
    initializeIcons();
    initializeFileTypeIcons();
    //initializeIcons('/assets/fluentui/fonts/');
    //initializeFileTypeIcons('/assets/fluentui/fonts/');

    let usuario = clientStore.usuario.getOrDefault();
    if (usuario && usuario.config) {
        themeService.applyTheme(usuario.config.temaOscuroActivado);
    }
    else
        themeService.applyTheme(false);
};

function resolveA2HS(): IA2HS {
    // Inspiration: https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen

    window.addEventListener('beforeinstallprompt', (e: any) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        let deferredPrompt = e;

        a2hsSubject.next({
            // Update UI to notify the user they can add to home screen
            showButton: true,
            // Callback 
            onUserOpenA2HSDialog: (onAccepted?: () => void) => {
                a2hsSubject.next({
                    // hide our user interface that shows our A2HS button
                    showButton: false,
                });

                // Show the prompt
                deferredPrompt.prompt();

                // Wait for the user to respond to the prompt
                deferredPrompt.userChoice.then((choiceResult: any) => {
                    if (choiceResult.outcome === 'accepted') {
                        //console.log('User accepted the A2HS prompt');
                        if (onAccepted) onAccepted();
                    } else {
                        //console.log('User dismissed the A2HS prompt');
                    }
                    deferredPrompt = null;
                });
            }
        });

    });

    return {
        showButton: false
    };
}
//#endregion
