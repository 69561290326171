import { httpCommandClient } from "client/infrastructure/http/httpClient";
import { IUsuarioAutenticado } from "client/store/model/usuario";

const autenticacionesAPI = {

    login: (email: string, password: string, recordarme: boolean) => httpCommandClient.postWithResponse<IUsuarioAutenticado>('Login', {
        email: email,
        password: password,
        recordarme: recordarme
    }),

    logout: () => httpCommandClient.post('Logout')

};

export default autenticacionesAPI;
