import React, { useState } from 'react';
import {
    Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton,
    Stack, TextField, MessageBar, MessageBarType, Text, Spinner, SpinnerSize
} from '@fluentui/react';
import { IDialogProps } from 'client/infrastructure/dialogs/dialogProps';
import usuariosAPI from 'client/pages/Usuarios/api/usuariosAPI';

export interface IRespuesta {
    success: boolean,
    mensaje: string
}

const RecuperarPasswordDialog: React.FC<IDialogProps<IRespuesta>> = (props) => {
    const [bloqueado, setBloqueado] = useState(false);
    const [email, setEmail] = useState('');
    const [errores, setErrores] = useState<string[] | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setBloqueado(true);
        setLoading(true);
        setErrores(undefined);
        const result = await usuariosAPI.recuperarPassword(email);
        if (result.success)
            props.onClose({ success: true, mensaje: `Fue enviado un correo de recuperación a ${email}, revisa el correo!` })
        else
            setErrores(result.messages);

        setLoading(false);
        setBloqueado(false);
    };

    const onDismiss = () => {
        props.onClose();

        setTimeout(() => {
            setBloqueado(false);
        }, 500);
    };

    return (
        <Dialog
            hidden={props.hideDialog}
            onDismiss={() => onDismiss()}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Recuperar contraseña',
                closeButtonAriaLabel: 'Cerrar',
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 450 } }
            }}
        >
            <Stack>
                <form onSubmit={onSubmit}>
                    <input type="submit" hidden />
                    <Text>Tenga en cuenta lo siguiente:</Text>
                    <Text block>Esta tarea enviara un enlace al correo que introduzca.</Text>
                    <TextField
                        autoFocus
                        label="Email"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        disabled={bloqueado}
                        required
                    />

                </ form>
                <br />

                {errores && errores.map(e => (
                    <MessageBar key={e} messageBarType={MessageBarType.error}>
                        {e}
                    </MessageBar>
                ))}
            </Stack>
            {loading && <Spinner size={SpinnerSize.xSmall} />}
            <DialogFooter>
                <PrimaryButton
                    onClick={onSubmit}
                    text="Aceptar"
                    disabled={bloqueado}
                />
                <DefaultButton
                    onClick={(e: any) => onDismiss()}
                    text="Cancelar"
                    disabled={bloqueado}
                />
            </DialogFooter>
        </Dialog>
    );
};

export default RecuperarPasswordDialog;