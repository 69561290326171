export interface IHttpHeader {
    key: string;
    value: string;
}

export abstract class DefaultHeaderKeys {
    public static readonly Autorization = 'Authorization';
    public static readonly UserPosition = 'User-Position';
    public static readonly Empresa = 'Empresa';
    public static readonly DisplayMode = "Display-Mode";
};