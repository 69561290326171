import React, { FC, useState } from 'react';
import {
    Dialog, DialogType, Icon, Link, MessageBar, MessageBarButton, MessageBarType, MotionAnimations, MotionDurations, Stack
} from '@fluentui/react';
import useScreenSize from './layouts/admin/useScreenSize';


interface IPWAUpdateDialogProps {
    hidden: boolean;
    onDismiss: () => void;
};

const PWAUpdateDialog: FC<IPWAUpdateDialogProps> = (props) => {
    const { hidden, onDismiss } = props;

    const [mostrarOpciones, setMostrarOpciones] = useState(false);

    // Hooks
    const { isPhoneScreenSize } = useScreenSize();

    return (
        <Dialog
            hidden={hidden}
            onDismiss={onDismiss}
            title='🌟 Nueva actualización instalada'
            dialogContentProps={{
                type: DialogType.largeHeader,
                closeButtonAriaLabel: 'Cerrar'
            }}
            modalProps={{
                isBlocking: true,
                styles: isPhoneScreenSize ? undefined : {
                    main: { minWidth: '450px !important' }
                }
            }}
            subText="Necesitamos reiniciar para aplicar los cambios. Por favor cierre la aplicación y vuélvala a iniciar. Si está en un navegador web cierre todas las pestañas abiertas."
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Link onClick={() => setMostrarOpciones(true)} disabled={mostrarOpciones}>
                    <Icon iconName="ChevronDown" />&nbsp;&nbsp;Mostrar opciones avanzadas
                </Link>
                {mostrarOpciones && <MessageBar
                    style={{
                        animation: MotionAnimations.slideDownIn,
                        animationDuration: MotionDurations.duration4
                    }}
                    messageBarType={MessageBarType.severeWarning}
                    actions={
                        <div>
                            <MessageBarButton onClick={onDismiss}>
                                Ignorar esta solicitud
                            </MessageBarButton>
                        </div>
                    }
                >
                    Si usted ignora la solicitud de reiniciar la aplicación pueden ocurrir errores inesperados. Si aún así desea continuar pulse el siguiente botón:
                </MessageBar>}
            </Stack>
        </Dialog>
    );
};

export default PWAUpdateDialog;