import deprecatedHttpClientProvider from "client/infrastructure/http/httpClient";

const httpClient = deprecatedHttpClientProvider('usuarios/');

const usuariosAPI = {

    //#region Invitacion para ser usuario
    invitarParaSerUsuario: (idPersona: string, email: string, gruposDeUsuarios?: string[]) =>
        httpClient.post('invitarParaSerUsuario', { idPersona, email, gruposDeUsuarios }),

    incluirGrupoEnInvitacionParaSerUsuario: (idInvitacion: string, idGrupo: string) => 
        httpClient.post('incluirGrupoEnInvitacionParaSerUsuario', { idInvitacion, idGrupo }),

    removerGrupoDeInvitacionParaSerUsuario: (idInvitacion: string, idGrupo: string) => 
        httpClient.post('removerGrupoDeInvitacionParaSerUsuario', { idInvitacion, idGrupo }),

    cancelarInvitacionParaSerUsuario: (cmd: { idInvitacion: string, motivo: string }) =>
        httpClient.post('cancelarInvitacionParaSerUsuario', cmd),

    reenviarEmailDeInvitacionParaSerUsuario: (idInvitacion: string) =>
        httpClient.post('reenviarEmailDeInvitacionParaSerUsuario', { idInvitacion }),
    //#endregion

    //#region Grupos de usuarios
    agregarUsuarioAGrupo: (cmd: { idUsuario: string, idGrupo: string }) =>
        httpClient.post('agregarUsuarioAGrupo', cmd),

    removerUsuarioDeGrupo: (cmd: { idUsuario: string, idGrupo: string }) =>
        httpClient.post('removerUsuarioDeGrupo', cmd),

    // #endregion

    //#region Foto de perfil
    agregarFotoDePerfilDeUsuario: (idFoto: string, idUsuario?: string) =>
        httpClient.post('agregarFotoDePerfilDeUsuario', idUsuario ? { idFoto, idUsuario } : { idFoto }),

    cambiarFotoDePerfilDeUsuario: (idFoto: string, idUsuario?: string) =>
        httpClient.post('cambiarFotoDePerfilDeUsuario', idUsuario ? { idFoto, idUsuario } : { idFoto }),
    //#endregion

    recuperarPassword: (email: string) =>
        httpClient.post('recuperarPassword', { email }),

    eliminarUsuario: (cmd: { idUsuario: string, motivo: string }) =>
        httpClient.post('eliminarUsuario', cmd),

};

export default usuariosAPI;