import { LsValue } from "../infrastructure/localStorage/lsValue";
import { IUsuarioAutenticado } from "./model/usuario";

/**
 *Los siguientes keys del local storage pertenecen a la version 
 * 1, de Angular, los cuales pueden estar todavia en los navegadores 
 * de los usuarios.
 * 
 * erp-usuario-autenticado
 * erp-schema-version
 * erp.common-dark-mode-enabled
 * erp.common-sidenav-end-opened
 * erp.common-sidenav-expandido
 * erp.warrant-control-stocks-paginator-pageSize
 * erp.warrant-controlStockFiltroPorEstadoDeStockDeCliente
 */

const clientStore = {
    // Asegurarse de limpiear todo en logout

    usuario: new LsValue<IUsuarioAutenticado>('usuarioAutenticado'),
    versionDeFotoDePerfil: new LsValue<number>('versionDeFotoDePerfil'),
    empresaEnContexto: new LsValue<string>('empresaEnContexto')
};

export default clientStore;