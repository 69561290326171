import { createTheme } from "@fluentui/react";

export const gicalLightTheme = createTheme({
    palette: {
        themePrimary: '#d43f00',
        themeLighterAlt: '#fdf6f3',
        themeLighter: '#f8dcd0',
        themeLight: '#f2bfa9',
        themeTertiary: '#e5855c',
        themeSecondary: '#d9531a',
        themeDarkAlt: '#be3900',
        themeDark: '#a13000',
        themeDarker: '#772400',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#ffffff',
    }
});

export const gicalDarkTheme = createTheme({
    semanticColors: {
        menuBackground: '#262625',
        //bodyBackground: '#262625' // this was fixed in an updated Fluent UI version

        // Message bar 
        messageText: '#f3f2f1',
        // Info
        infoBackground: '#323130',
        infoIcon: '#c8c6c4',
        // Error
        errorBackground: '#442726',
        errorIcon: '#f1707b',
        // Success
        successBackground: '#393d1b',
        successIcon: '#92c353',
        // Warning
        warningBackground: '#433519',
        warningIcon: '#c8c6c4',
        // Severe warning
        severeWarningBackground: '#4f2a0f',
        severeWarningIcon: '#fce100'
    },
    palette: {
        themePrimary: '#e3872b',
        themeLighterAlt: '#090502',
        themeLighter: '#241607',
        themeLight: '#44290d',
        themeTertiary: '#88511a',
        themeSecondary: '#c87726',
        themeDarkAlt: '#e6923e',
        themeDark: '#eaa25a',
        themeDarker: '#efb983',
        neutralLighterAlt: '#262625',
        neutralLighter: '#2f2e2e',
        neutralLight: '#3d3d3c',
        neutralQuaternaryAlt: '#464645',
        neutralQuaternary: '#4d4d4c',
        neutralTertiaryAlt: '#6b6b6b',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#141414',
    }
});