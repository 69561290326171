export interface IUserGeolocationProvider {
    getPosition(): IUserPosition;
};

export interface IUserPosition {
    success: boolean;
    errorCode?: string;
    latitude?: number;
    longitude?: number;
    accuracy?: number;
    altitude?: number | null;
    altitudeAccuracy?: number | null;
    heading?: number | null;
    speed?: number | null;
    timestamp?: Date;
}

export class UserPositionErrorCodes {
    readonly notSupported = 'NOT_SUPPORTED';
    readonly notYetResolved = 'NOT_YET_RESOLVED';
    readonly permissionDenied = 'PERMISSION_DENIED';
    readonly positionUnavailable = 'POSITION_UNAVAILABLE';
    readonly timeout = 'TIMEOUT';
    readonly unknownError = 'UNKNOWN_ERROR';
};
