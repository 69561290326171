import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React, { FC } from 'react';
import transitions from 'client/infrastructure/styles/transitions/transitions';

interface ILoadingProps { };

const Loading: FC<ILoadingProps> = (props) => {
    const { } = props;

    return (
        <Stack verticalAlign="center" style={{ height: '80%', width: '100%', position: 'absolute' }}>
            <Stack.Item align="center">
                <Spinner className={transitions.fadeIn500ms} size={SpinnerSize.large} label="Cargando..." ariaLive="assertive" labelPosition="right" />
            </Stack.Item>
        </Stack>
    );
};

export default Loading;