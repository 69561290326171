import { useRef, useEffect, useCallback } from "react";
import { ICancellablePromise, ICancellableTypedPromise } from './usePromises.types';

const usePromises = () => {
    // thanks to: https://medium.com/@rajeshnaroth/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f

    // think of useRef as member variables inside a hook
    // you cannot define promises here as an array because
    // they will get initialized at every render refresh
    const promises = useRef<ICancellablePromise[]>([]);

    // cancelablePromise remembers the promises that you
    // have called so far. It returns a wrapped cancelable
    // promise
    const newPromise = useCallback(<T>(promise: Promise<T>) => {
        const wrappedPromise = createCancellablePromise(promise);
        promises.current.push(wrappedPromise);
        return wrappedPromise.promise;
    }, []);

    // useEffect initializes the promises array
    // and cleans up by calling cancel on every stored
    // promise.
    // Empty array as input to useEffect ensures that the hook is
    // called once during mount and the cancel() function called
    // once during unmount
    useEffect(() => () => {
        promises.current.forEach(x => x.cancel());
        promises.current = [];
    }, []);

    return { newPromise };
};

export default usePromises;

function createCancellablePromise<T>(promise: Promise<T>): ICancellableTypedPromise<T> {
    
    let isCanceled = false;

    const wrappedPromise =
        new Promise<T>((resolve, reject) => {
            // No reject called? No problem! 
            // More info: https://stackoverflow.com/questions/36734900/what-happens-if-you-dont-resolve-or-reject-a-promise
            
            promise
                .then(value =>
                    isCanceled
                        ? console.debug('Promise canceled due to component unmount')
                        : resolve(value))
                .catch(error =>
                    isCanceled
                        ? console.debug('Promise canceled due to component unmount')
                        : reject(error)
                );
        });

    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        }
    } as ICancellableTypedPromise<T>
}