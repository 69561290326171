import { BehaviorSubject, Subscription } from 'rxjs';

export class LsValue<T> {
    private subject = new BehaviorSubject<T | undefined>(undefined);

    constructor(
        private key: string
    ) {
    }

    getOrDefault(): T | undefined {
        let cached = this.subject.getValue();
        if (cached)
            return cached;

        let serialized = localStorage.getItem(this.key);
        if (!serialized)
            return undefined;

        let value = JSON.parse(serialized) as T;
        this.subject.next(value);
        return value;
    }

    get(): T {
        let value = this.getOrDefault();
        if (!value)
            window.alert(`ClientStoreException: Value of type ${this.key} was not found in local storage`);

        return value as T;
    }

    remove() {
        localStorage.removeItem(this.key);
        this.subject.next(undefined);
    }

    save(value?: T) {
        if (value !== null && value !== undefined) {
            let serialized = JSON.stringify(value);
            localStorage.setItem(this.key, serialized);
            this.subject.next(JSON.parse(serialized));
        }
        else {
            localStorage.removeItem(this.key);
            this.subject.next(undefined);
        }
    }

    subscribe(next?: (value: T | undefined) => void): Subscription {
        return this.subject.subscribe(next);
    }
}