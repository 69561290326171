import tokenResolverProvider, { ITokenResolver } from './tokenResolver';
import axios from 'axios';
import { BehaviorSubject } from 'rxjs';
import userGeolocationProvider from 'client/infrastructure/geolocation/userGeolocationProvider';
import { IUserGeolocationProvider } from 'client/infrastructure/geolocation/userGeolocationProvider.types';
import { IDeprecatedHttpClient, Result, Response, IHttpCommandClient, IHttpQueryClient } from './httpClient.types';
import { DefaultHeaderKeys } from './httpDefaultHeadersProvider.types';
import httpHeadersProvider from './httpDefaultHeadersProvider';

const unauthorizedErrorStream = new BehaviorSubject<any>(undefined);

export function getUnauthorizedErrorStream() {
    // this can be abstracted away if we ever do test this httpClient service.
    return unauthorizedErrorStream.asObservable();
}

const deprecatedHttpClientProvider: (routePrefix: string) => IDeprecatedHttpClient =
    (routePrefix: string) => {

        return new ProductionHttpClient(`/api/${routePrefix}`,
            tokenResolverProvider.getTokenResolver,
            userGeolocationProvider);
    };

export default deprecatedHttpClientProvider;

class ProductionHttpClient implements IDeprecatedHttpClient, IHttpCommandClient, IHttpQueryClient {

    constructor(
        private routePrefix: string,
        private tokenResolverFactory: () => ITokenResolver | undefined,
        private userPositionProvider: IUserGeolocationProvider
    ) {
    }

    postWithResponse<T>(relativeUrl: string, body?: any): Promise<Response<T>> {
        return axios.request<Response<T>>({
            method: 'post',
            url: this.routePrefix + relativeUrl,
            headers: this.resolveHeaders(),
            data: body ?? {},
        })
            .then(axiosResponse => {
                const data = axiosResponse.data;
                // This is to enable the methods found in Result. Eg. GetPayload<T>()
                return new Response<T>(data.success, data.payload, data.messages);
            })
            .catch(error => {
                return this.onErrorResponse<T>(error);
            });
    }

    postFileWithResponse<T>(relativeUrl: string, file: File,
        onUploadProgress: (progresEvent: ProgressEvent) => void): Promise<Response<T>> {
        const formData = new FormData();
        formData.append(file.name, file);

        return axios.request<Response<T>>({
            method: 'post',
            url: this.routePrefix + relativeUrl,
            headers: this.resolveHeaders(),
            data: formData,
            onUploadProgress: onUploadProgress
        })
            .then(axiosResponse => axiosResponse.data)
            .catch(reason => this.onErrorResponse<T>(reason));
    }

    post(relativeUrl: string, body?: any): Promise<Result> {
        return axios.request<Result>({
            method: 'post',
            url: this.routePrefix + relativeUrl,
            headers: this.resolveHeaders(),
            data: body ?? {},
        })
        .then(axiosResponse => {
            return axiosResponse.data;
        })
        .catch(error => {
            return this.onError(error);
        });
    }

    get<T>(relativeUrl: string, body?: any): Promise<Response<T>> {
        return axios.request<Response<T>>({
            method: 'get',
            url: this.routePrefix + relativeUrl,
            headers: { ...this.resolveHeaders(), "Body": body ? window.encodeURIComponent(window.JSON.stringify(body)) : window.encodeURIComponent('{}') },
        })
        .then(axiosResponse => {
            return axiosResponse.data;
        })
        .catch(error => {
            return this.onErrorResponse<T>(error);
        });
    }

    getUrlWithToken(relativeUrl: string): string {
        let token = this.resolveToken();
        if (!token)
            return this.routePrefix + relativeUrl;

        if (relativeUrl.includes('?'))
            return `${this.routePrefix}${relativeUrl}&token=${token}`;
        else
            return `${this.routePrefix}${relativeUrl}?token=${token}`;
    }

    private resolveHeaders() {
        let headers: any = {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
        };
        let token = this.resolveToken();
        if (token)
            headers[DefaultHeaderKeys.Autorization] = token;

        headers[DefaultHeaderKeys.UserPosition] = JSON.stringify(this.userPositionProvider.getPosition());

        // Aditional headers
        httpHeadersProvider
            .getHeaders()
            .forEach(x => {
                headers[x.key] = x.value;
            });

        return headers;
    }

    private resolveToken(): string | undefined {
        const tokenResolver = this.tokenResolverFactory();
        if (tokenResolver) {
            return tokenResolver.getToken();
        }

        return undefined;
    }

    private onErrorResponse<T>(error: any): Response<T> {
        return this.onError(error) as Response<T>;
    };

    private onError(error: any): Result {
        const response = error.response;
        if (!response)
            return new Result(false, ['Error al conectarse al servidor, verifique su conexión a la red o internet', error.message])

        const status = response.status
        if (status === 401) { // Unauthorized
            unauthorizedErrorStream.next(error);
            return new Result(false, [
                'Debe iniciar sesión para continuar'
            ]);
        }
        else if (status === 403) { // Forbidden
            if (response.error) {
                return new Result(false, response.error as string[]);
            }
            else if (response.data) { // Axios update?
                return new Result(false, response.data as string[]);
            }
            else
                return new Result(false, ['Lo sentimos, pero la verdad es que por el momento no tiene los permisos necesarios.']);
        }
        else if (status === 400) { // Bad Request
            if (response.error) {
                return new Result(false, response.error as string[]);
            }
            else if (response.data) { // Axios update?
                return new Result(false, response.data as string[]);
            }
            else
                return new Result(false, ['Error desconocido. Por favor consulte con el administrador.']);
        }

        return new Result(false, [ // 500? Internal server error
            'Ocurrió un error inesperado. Puede intentar otra vez, pero si no pasa le recomendamos contactar con el administrador del sistema'
        ]);
    }
};

export const httpCommandClient: IHttpCommandClient = new ProductionHttpClient(`/api/erp/command/`,
    tokenResolverProvider.getTokenResolver,
    userGeolocationProvider);

export const httpQueryClient: IHttpQueryClient = new ProductionHttpClient(`/api/erp/query/`,
    tokenResolverProvider.getTokenResolver,
    userGeolocationProvider);