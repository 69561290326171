import React, { useState, useRef, useEffect } from 'react';
import { Dialog, Text, DialogType, DialogFooter, PrimaryButton, DefaultButton, Stack, TextField, MessageBar, MessageBarType, Checkbox, IconButton, TeachingBubble, Link } from '@fluentui/react';
import { IDialogProps } from 'client/infrastructure/dialogs/dialogProps';
import { IUsuarioAutenticado } from 'client/store/model/usuario';
import RecuperarPasswordDialog, { IRespuesta } from './RecuperarPassword';
import loginService from 'client/services/usuarios/loginService';
import usePromises from 'client/infrastructure/promises/usePromises';

export interface ILoginDialogProps extends IDialogProps<IUsuarioAutenticado> {
    authError?: any;
}

const LoginDialog: React.FC<ILoginDialogProps> = (props) => {
    const spanRef = useRef<HTMLSpanElement>(null);
    const [isTeachingBubbleVisible, setIsTeachingBubbleVisible] = useState(false);
    const [recordarme, setRecordarme] = useState(true);
    const { newPromise } = usePromises();
    const timeoutRef = useRef<number | undefined>(undefined);

    const [hideDialogRecuperarPassword, setHideDialogRecuperarPassword] = useState(true);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [bloqueado, setBloqueado] = useState(false);
    const [errores, setErrores] = useState<string[] | undefined>(undefined);
    const [respuestaRecuperacionPassword, setRespuestaRecuperacionPassword] = useState<IRespuesta>({ success: false, mensaje: '' });

    // FX
    useEffect(() => {
        return () => {
            window.clearTimeout(timeoutRef.current);
        }
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();

        setBloqueado(true);
        newPromise(loginService.login(email, password, recordarme))
            .then(r => {
                setRecordarme(false);
                if (r.success) {
                    onDismiss(r.payload);
                }
                else {
                    setErrores(r.messages);
                    setBloqueado(false);
                }
            });
    };

    const onDismiss = (usuarioAutenticado?: IUsuarioAutenticado) => {
        setRespuestaRecuperacionPassword({ success: false, mensaje: '' })
        props.onClose(usuarioAutenticado);

        timeoutRef.current = window.setTimeout(() => {
            setEmail('');
            setPassword('');
            setErrores(undefined);
            setBloqueado(false);
        }, 500);
    };

    const onCloseRecuperarPassword = (result?: IRespuesta) => {
        if (result)
            setRespuestaRecuperacionPassword(result);

        setHideDialogRecuperarPassword(true);
    }

    return (
        <Dialog
            hidden={props.hideDialog}
            onDismiss={() => onDismiss()}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Iniciar sesión',
                subText: props.authError ? 'Debe iniciar sesión para continuar' : undefined,
                closeButtonAriaLabel: 'Cerrar',
            }}
            modalProps={{
                isBlocking: false,
                styles: { main: { maxWidth: 450 } }
            }}>

            <Stack>
                <form onSubmit={onSubmit}>
                    <input type="submit" hidden />

                    <TextField
                        autoFocus
                        label="Email"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        disabled={bloqueado}
                        required
                    />
                    <TextField
                        label="Contraseña"
                        type="password"
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                        disabled={bloqueado}
                        required
                    />
                    <br />
                    <Stack tokens={{ childrenGap: 8 }} horizontal verticalAlign="center">
                        <Checkbox label="Recordarme" checked={recordarme} onChange={(e, cheked) => cheked ? setRecordarme(true) : setRecordarme(false)} />
                        <span ref={spanRef}>
                            <IconButton iconProps={{ iconName: 'StatusCircleQuestionMark' }} onClick={(e) => setIsTeachingBubbleVisible(!isTeachingBubbleVisible)} />
                        </span>
                    </Stack>
                </ form>
                <br />
                <Text style={{ marginTop: 10 }}>
                    <Link onClick={() => {
                        setHideDialogRecuperarPassword(false)
                    }}>Olvide mi contraseña
                        </Link></Text>
                <br />
                {errores && errores.map(e => (
                    <MessageBar key={e} messageBarType={MessageBarType.error}>
                        {e}
                    </MessageBar>
                ))}
                {respuestaRecuperacionPassword.success &&
                    <MessageBar key={'men'} messageBarType={MessageBarType.success}>
                        {respuestaRecuperacionPassword.mensaje}
                    </MessageBar>
                }
                {isTeachingBubbleVisible &&
                    <TeachingBubble
                        target={spanRef}
                        onDismiss={(e) => setIsTeachingBubbleVisible(false)}
                        headline="¿Ya no quieres introducir tus credenciales todo el tiempo?">
                        Marcar esta casilla permitirá que el sistema recuerde sus credenciales el máximo tiempo posible, para evitar pedirle nuevamente sus credenciales muy seguido.
                </TeachingBubble>
                }
                <RecuperarPasswordDialog hideDialog={hideDialogRecuperarPassword} onClose={onCloseRecuperarPassword} />
            </Stack>
            <DialogFooter>
                <PrimaryButton
                    onClick={onSubmit}
                    text="Aceptar"
                    disabled={bloqueado}
                />
                <DefaultButton
                    onClick={(e: any) => onDismiss()}
                    text="Cancelar"
                    disabled={bloqueado}
                />
            </DialogFooter>
        </Dialog>
    );
};

export default LoginDialog;