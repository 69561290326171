import themePaletteProvider from "./themePaletteProvider";
import { loadTheme } from "@fluentui/react";
import clientStore from "client/store/clientStore";

const GLOBAL_CSS_ID = 'global-theme-styles';

const themeService = {

    applyTheme: (applyDarkTheme: boolean) => {
        let theme = applyDarkTheme ? themePaletteProvider.dark : themePaletteProvider.light;

        loadTheme(theme);

        /* Color */
        let bodyStyle = document.body.style;
        bodyStyle.backgroundColor = theme.palette.white;
        bodyStyle.color = theme.palette.black;

    /* Global theme styles */

        const oldElement = document.getElementById(GLOBAL_CSS_ID);
        if (oldElement)
            document.head.removeChild(oldElement);

        const link = document.createElement('link');

        link.id = GLOBAL_CSS_ID;

        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = applyDarkTheme ? '/css/global-css-dark.css' : '/css/global-css-light.css';

        document.head.appendChild(link);
    },

    resolveIfDarkThemeIsActive: (): boolean => {
        const usuarioAutenticado = clientStore.usuario.getOrDefault();
        if (usuarioAutenticado && usuarioAutenticado.config.temaOscuroActivado)
            return true;
        else
            return false;
    }
};

export default themeService;